<template>
  <div>
    <el-dialog title="查询账号找回结果" :visible.sync="dialogVisible" style="margin-top:20vh !important" custom-class="background change-phone-dialog g-dialog-style" width="30%" :before-close="handleClose">
      <div>
        <el-form :model="form" :rules="error" label-width="100px" ref="form" class="form">

          <el-form-item label="手机号" prop="iphone">
            <el-input v-model="form.iphone" class="wrap_input w336" @change="getCodeBtnDisable = false" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="短信验证码" prop="code">
            <el-input class="wrap_input w336" v-model="form.code" placeholder="请输入短信验证码" onkeyup="this.value=this.value.replace(/^ +| +$/g,'')">
              <template slot="append">
                <el-button @click="sendMsg" class="sendcode" :class="{ 'disabled-style': getCodeBtnDisable }" :disabled="getCodeBtnDisable">{{ btntxt }}
                </el-button>
              </template>
            </el-input>
          </el-form-item>

        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onConfirm">确 定</el-button>
      </span>

    </el-dialog>
    <imageCodeDialog @code="getPicCode" ref="picCodeDialog"></imageCodeDialog>
  </div>
</template>
<script>
import imageCodeDialog from '@/components/login/imageCodeDialog.vue';
export default {
  components: {
    imageCodeDialog
  },
  data() {
    return {
      dialogVisible: false,
      getCodeBtnDisable: false,
      btntxt: '获取验证码',
      form: {
        iphone: '',
        code: ''
      },
      error: {
        iphone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '手机号格式不对',
            trigger: 'blur'
          }
        ],
        code: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    sendMsg() {
      this.$refs['form'].validateField('iphone', valid => {
        if (valid.length == 0) {
          // 发送验证码
          this.$refs.picCodeDialog.open();
        }
      });
    },
    getPicCode(val) {
      let { code, uuid } = val;
      this.getCodeBtnDisable = true;
      this.btntxt = '发送中...';
      this.$axios
          .post('/api/app-jycy-phoneverificationcode/verification', {
            code: code,
            phone: this.form.iphone,
            uuid: uuid
          })
          .then(res => {
            if (res.data.success) {
              this.$message.success({
                message: '验证码已经发送至您的手机，请注意接收'
              });
              this.disabled = false;
              this.time = 180;
              this.timer();
            } else {
              this.getCodeBtnDisable = false;
              this.btntxt = '获取验证码';
              this.$message.error({
                message: res.data.msg || '发送失败'
              });
            }
          });
    },
    //倒计时
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = this.time + 's后重新获取';
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = '获取验证码';
        this.getCodeBtnDisable = false;
      }
    },
    open() {
      this.dialogVisible = true;
      this.form = {
        iphone: '',
        code: ''
      }
    },
    onConfirm() {

      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$axios
              .post('/api/app-jycy-company/findPwd/companyAppealResult', {
                code: this.form.code,
                iphone: this.form.iphone
              })
              .then(res => {
                if (res.data.success) {
                  // 0 未审核，1是审核通过，2是审核不通过，2有原因  3是没有记录

                  if (res.data.data == 0 || res.data.data == 3) {
                    let msg = res.data.data == 0 ?'您的申请暂未审核，请您耐心等待！' : '未查询到账号找回申请记录'
                    this.$confirm(
                        msg,
                        '提示',
                        {
                          confirmButtonText: '回首页',
                          cancelButtonText:'取消',
                          type: 'warning'
                        }
                    )
                        .then(() => {
                          this.$router.push('/');
                        })
                    return
                  }
                  if (res.data.data == 2) {
                    this.$confirm(
                        `您的账号找回申请审核未通过，原因为 ${res.data.msg}，请重新进行找回!`,
                        '提示',
                        {
                          confirmButtonText: '重新申请',
                          cancelButtonText: '回首页',
                          type: 'warning'
                        }
                    )
                        .then(() => {
                          this.dialogVisible = false;
                        })
                        .catch(() => {
                          this.$router.push('/');
                        });
                    return
                  }
                  if (res.data.data == 1) {
                    this.$confirm(
                        '您的企业账号找回申请已审核通过，可使用该手机号+短信验证码登录，记得完善企业信息，发布职位哦!',
                        '提示',
                        {
                          confirmButtonText: '去登录',
                          cancelButtonText: '回首页',
                          type: 'warning'
                        }
                    )
                        .then(() => {
                          this.$router.push('/user/companyLogin');
                        })
                        .catch(() => {
                          this.$router.push('/');
                        });
                    return
                  }
                  this.$message.error(res.data.msg )
                }else{
                  this.$message.error(res.data.msg )
                }
              });
        }
      });
    },
    handleClose(){
      this.dialogVisible = false;
    }
  }
};
</script>
<style scoped lang="less">
/deep/ .form {
  .el-form-item {
    .el-input-group--append {
      border: 1px solid #cecfd0;
      border-radius: 6px;

      .el-input__inner {
        border: none;
      }
    }
    .el-input-group__append {
      border: none;
      padding: 0 10px;
      background: rgba(0, 0, 0, 0.03);

      span {
        display: inline-block;
        font-size: 14px;
        color: #66716c;
        padding-left: 20px;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
      }

      .el-button.is-disabled,
      .el-button.is-disabled:hover,
      .el-button.is-disabled:focus {
        border: none;
        background: none;
        color: #00924b;
      }
    }
  }
  .btn {
    margin-top: 46px;
  }
}
</style>
