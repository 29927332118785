<template>
  <div class="imageChecking">
    <div class="interinputs">
      <el-input
        type="text"
        v-model="identifyCode"
        :class="codeImg"
        @keyup.enter.native="submits"
        @change="changeCode"
        placeholder="图片中验证码"
      >
        <template slot="append">
          <img :src="imgUrl.img" @click="getCaptcha();" alt="验证码(点击换一张)"  class="err_image"/>
          <span class="horn" style="border:none" @click="reads()">
          <img src="@/static/login/play.png" alt="点击播放验证码" style="width:18px;height:18px"><p>播放</p>
          </span>
        </template>
      </el-input>
    </div>
    <!-- <p class="reads">点击验证码无障碍阅读</p> -->
  </div>
</template>

<script>
export default {
  name: "imagecode",
  props:{
    codeImg:''
  },
  data() {
    return {
      imgUrl: [],
      identifyCode: "",
    };
  },

  methods: {
    changeCode(val) {
      this.$emit("changeCode", {uuid:this.imgUrl.uuid,code:val});
    },
    getCaptcha() {
      let temp = new Date().getTime();
      this.$api.loginImageApi({temp: temp}).then(res =>{
        if (res.data.success){
          this.imgUrl = res.data.data;
          this.imgUrl.img = 'data:image/jpeg;base64,' + this.imgUrl.img;
        }
      })
    },
    reads(){
      window.toolbar.captchaSpeaker(this.imgUrl.code)
    },
    submits() {
      this.$emit("submits");
    },
  },
  mounted() {
    this.getCaptcha();
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-input-group__append {
  color: #00924c;
  padding: 0px;
  padding-left: 12px !important;

}

p {
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  text-align: right;
  font-size: 12px;
  color: #666666;
  // margin-top: 2px;
}

.interinputs {
  display: flex;
  background: #ffffff;
  align-items: center;

  /deep/ .el-input__inner {
    border-radius: 4px 0px 0px 4px;
  }
}
/deep/.codeImg{
  .el-input-group__append{
    padding: 0 !important;
    img{
      width: 85px;
      height: 40px;
    }
  }
}
.horn{
  border: none;
  img{
    transform: translateY(-4px);
  }
  p{
    transform: translateY(-8px);
    display: inline-block;
    border: none;
font-size: 14px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 600;
color: #28A46D;
line-height: 22px;
cursor: pointer;
  }
}
</style>
