<template >
  <el-dialog custom-class="el-dialog_picCode"   title="图形验证码" :visible.sync="isPicCode" :modal-append-to-body="true" :append-to-body="true" width="420px" :before-close="handleClose">
    <div class="py16">
      <ImageCode @changeCode="getchangeCode" ref="ImageCode1"></ImageCode>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="onConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import ImageCode from './ImageCode.vue';

export default {
  components: {
    ImageCode
  },
  data() {
    return {
      isPicCode: false,
      code: ''
    };
  },
  methods: {
    getchangeCode(val) {
      this.code = val;
    },
    open() {
      this.isPicCode = true;
      this.$nextTick(() => {
        this.$refs.ImageCode1.getCaptcha();
      });
    },
    onConfirm() {
      this.$emit('code', this.code);
      this.handleClose();
    },
    handleClose() {
      this.isPicCode = false;
      this.code = '';
      this.$refs.ImageCode1.identifyCode = ''
    }
  }
};
</script>
<style lang="less" >
.el-dialog_picCode {
  z-index: 3001;
  .el-dialog__body {
    padding: 5px 20px 15px;
  }
}
</style>
