<template>
  <div class="retrieve">
    <div class="content">
      <div class="title">
        <h3>兰州市残疾人就业创业网络服务平台 | 账号安全</h3>
        <router-link to="/">返回首页</router-link>
      </div>
      <div class="color-E9951E mt4 pointer" @click="$refs.findAccountResult.open()">查询账号找回结果</div>
      <h2>
        请输入公司名称
      </h2>
      <el-form
          :model="form"
          :rules="error"
          label-width="100px"
          ref="form"
          class="form">
        <el-form-item label="公司名称" prop="name">
          <el-input
              v-model="form.name"
              @change="getCompanyByName"
              class="wrap_input w336"
              placeholder="请输入与营业执照一致的公司全称"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="userName">
          <el-input
              v-model="form.userName"
              @change="getIphone"
              class="wrap_input w336"
              placeholder="请输入您的手机号用于账号找回"
          ></el-input>
        </el-form-item>
        <el-form-item label="短信验证码" prop="password">
          <el-input
              class="wrap_input w336"
              v-model="form.password"
              @change="disabled = false"
              placeholder="请输入短信验证码"
              onkeyup="this.value=this.value.replace(/^ +| +$/g,'')"
          >
            <template slot="append">
              <el-button
                  @click="isSend"
                  class="sendcode"
                  :class="{ 'disabled-style': getCodeBtnDisable }"
                  :disabled="getCodeBtnDisable"
              >{{ btntxt }}
              </el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="tips red" v-if="getLoginShow">
            <span style="font-weight: 600;">{{ form.name }}</span>未在平台注册过账号，您可直接<router-link style="color:#28a46d;" target="_blank"
                                                                                                          :to="{path:'/user/companyLogin'}">注册企业账号</router-link> ，获取平台服务
          </div>
          <div class="tips" v-if="phoneStatus == 1">
            <div class="tip_content">
              该手机号码已通过残疾人身份认证，不可绑定企业账号，请您更换手机号。
            </div>
          </div>
          <div class="tips" v-if="phoneStatus == 2">
            <div class="tip_content">
              该手机号码已关联其他公司账号，需解除绑定，才可绑定当前账号。
              <router-link :to="'/enterprise/centre/setting/unbind?type=1&phone='+form.phone+'&url=/user/companyLogin'">
                去解除绑定>>
              </router-link>
            </div>
          </div>
          <el-button :type="disabled?'info':'primary'" class="btn w336" @click="register('form')" :disabled="disabled">
            下一步
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <dialogImageCode ref="dialogImageCode" @changeCode="sendMsg"/>
    <imageCodeDialog @code="getPicCode" ref="picCodeDialog" />
    <findAccountResult ref="findAccountResult"/>
  </div>
</template>

<script>
import dialogImageCode from "components/login/dialogImageCode";
import imageCodeDialog from '@/components/login/imageCodeDialog.vue';
import findAccountResult from "./components/findAccountResult";
export default {
  name: "retrieve",
  inject: ["reload"],
  components: {dialogImageCode,imageCodeDialog,findAccountResult},
  data() {
    return {
      form: {
        name: '',
        userName: '',
        password: '',
        loginType: "1",
        touristType: '2',
        registerSite: 'lz',
      },
      error: {
        name: [
          {
            required: true,
            message: "公司名称不能为空",
            trigger: "blur",
          },
        ],
        userName: [
          {
            required: true,
            message: "请输入您的手机号码",
            trigger: "blur",
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不对",
            trigger: "blur",
          },
        ],
        password: [
          {required: true, message: "请输入收到的验证码", trigger: "blur"},
        ],
      },
      disabled: true,
      btntxt: "获取验证码",
      time: 180,
      getCodeBtnDisable: false,
      phoneStatus: '',
      getLoginShow: false,
    }
  },
  methods: {
    isSend() {
      this.$refs.dialogImageCode.open();
    },
    sendMsg(val) {
      this.getCodeBtnDisable = true;
      this.btntxt = '发送中...';
      this.$axios
          .post(
              "/api/app-jycy-phoneverificationcode/verification",
              {
                phone: this.form.userName,
                uuid: val.uuid,
                code: val.code
              }
          ).then(res => {
        if (res.data.success) {
          this.$message.success({
            message: "验证码已经发送至您的手机，请注意接收",
          });
          this.disabled = false;
          this.time = 180;
          this.timer();
        } else {
          this.getCodeBtnDisable = false;
          this.btntxt = '获取验证码';
          this.$message.error({
            message: res.data.msg || "发送失败",
          });
        }
      })
      this.$refs.dialogImageCode.close();
    },
    getPicCode(val) {
      let { code, uuid } = val;
      this.getCodeBtnDisable = true;
      this.btntxt = '发送中...';
      this.$axios
          .post('/api/app-jycy-phoneverificationcode/verification', {
            code: code,
            phone: this.form.userName,
            uuid: uuid
          })
          .then(res => {
            if (res.data.success) {
              this.$message.success({
                message: '验证码已经发送至您的手机，请注意接收'
              });
              this.disabled = false;
              this.time = 180;
              this.timer();
            } else {
              this.getCodeBtnDisable = false;
              this.btntxt = '获取验证码';
              this.$message.error({
                message: res.data.msg || '发送失败'
              });
            }
          });
    },
    //倒计时
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = this.time + "s后重新获取";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "获取验证码";
        this.getCodeBtnDisable = false;
      }
    },
    //手机号是否已绑定
    getIphone(val) {
      this.$api.queryMemberTypeByPhoneApi({phone: val}).then(res => {
        if (res.data.success) {
          if (res.data.success) {
            if (res.data.data == '0') {
              this.disabled = false;
            } else {
              this.disabled = true;
            }
            this.phoneStatus = res.data.data;
          }
        }
      })
    },
    //根据公司名查询公司是否已注册
    async getCompanyByName(val) {
      let res = await this.$api.getCompanyByNameApi({compName:val});
      if (res.data.success) {
        this.$message.success('该企业主体为本平台企业用户');
        this.getLoginShow = false;
      } else {
        if (res.data.msg == '未注册') {
          this.$confirm('该企业主体暂未注册为本平台企业用户，请您先行注册', '提示', {
            confirmButtonText: '去注册',
            cancelButtonText: '回首页',
            type: 'warning',
            distinguishCancelAndClose: true
          })
              .then(() => {
                this.$router.push('/user/companyLogin?active=0');
              })
              .catch((action) => {
                this.$router.push('/');
              });
        }
        this.getLoginShow = true;
      }
    },
    //手机号登录
    register() {
      let that = this;
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.disabled = false;
          let res = await this.$api.getLoginApi(this.form);
          if (res.data.success) {
            this.loading = false;
            let user = res.data.data;
            that.$message.success('登录成功！');
            //残疾人个人信息
            that.$store.commit("SET_USER_INFO", user);
            //存储access_token
            that.$store.commit("SET_TOKEN", user.token);
            //存储refresh_token
            that.$store.commit("SET_REFRESH_TOKEN", user.token);
            this.reload();
            this.$router.push('/companyAudit?name=' + this.form.name);
          } else {
            that.$message.error(res.data.msg);
          }
        } else {
          this.$message.error("信息填写不完整");
        }
      })
    },
  },
  created() {

  }
}
</script>

<style scoped lang="less">
@import '~assets/css/setting.less';

.retrieve {
  width: 100%;
  min-height: calc(100vh - 269px);
  background: white;

  .w336 {
    width: 336px;
  }

  .content {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 100px;

    .title {
      display: flex;
      padding-top: 26px;

      h3 {
        flex: 1;
        font-size: 18px;
        color: #28a46d;
      }

      a {
        font-size: 18px;
        color: #66716c;
      }

      a:hover {
        color: #28a46d;
      }
    }

    h2 {
      font-size: 20px;
      text-align: center;
      margin-top: 100px;
    }

    /deep/ .form {
      width: 792px;
      margin: 0 auto;
      padding: 36px 109px;

      .el-form-item {
        .el-input__inner::placeholder {
          font-size: 14px;
          color: #99a09d;
        }

        .el-input-group--append {
          border: 1px solid #cecfd0;
          border-radius: 6px;

          .el-input__inner {
            border: none;
          }
        }

        .el-input-group__append {
          border: none;
          padding: 0 10px;
          background: rgba(0, 0, 0, 0.03);

          span {
            display: inline-block;
            font-size: 14px;
            color: #66716c;
            padding-left: 20px;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
          }

          .el-button.is-disabled,
          .el-button.is-disabled:hover,
          .el-button.is-disabled:focus {
            border: none;
            background: none;
            color: #00924b;
          }
        }
      }

      .btn {
        margin-top: 46px;
      }
    }

    .tips {
      width: 350px;

      .tip_content {
        line-height: 1.8em;
        color: #666666;

        a {
          color: #28A46D;
        }
      }
    }
  }
}
.color-E9951E{
  text-align: right;
  margin-top: 12px;
  cursor: pointer;
  color: #E9951E;
}
</style>
