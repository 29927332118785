<template>
  <el-dialog
      :visible.sync="isShow"
      width="350px"
      title="图形验证"
      :append-to-body="true"
      :before-close="close">
    <ImageCode @changeCode="getchangeCode" ref="myImageCode" />
    <div class="footer" slot="footer" style="text-align: center;margin-top: -20px">
      <el-button type="primary" style="width: 120px">发送</el-button>
    </div>
  </el-dialog>
</template>

<script>
import ImageCode from "@/components/login/ImageCode"
export default {
  name: "dialogImageCode",
  components:{ImageCode},
  data(){
    return{
      isShow:false,
    }
  },
  methods:{
    open(){
      this.isShow = true;
    },
    close(){
      this.isShow = false;
      this.$refs.myImageCode.getCaptcha();
    },
    getchangeCode(val) {
      this.$emit("changeCode",val)
    },
  }
}
</script>

<style scoped lang="scss">

</style>
